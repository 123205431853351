require('bc-image-loader');

const $ = require('jquery');
global.jQuery = $;

// Initialize all global stuff with data from back-end
const { initGlobals } = require('../../common/js/commonSetup');
initGlobals();

require('../../../../shared/lib/handlebarsHelpersTime')();
require('../../common/js/sorting');
const { videoFinisher } = require('../../common/js/videofinisher');
const BcVideoPlayer = require('./bc-videoplayer');

class BcLandingPage {
  constructor () {
    this.wasInteracted = false;
  }

  init () {
    this.initStudio();
    this.initVideoPlayer();
    this.initInteractions();
    this.resizeVideoPlayer();
    this.resizeHeader();
    this.resizeFooter();
  }

  initStudio () {
    const $addLinkButton = $('.header__dropdown-links').find('.edit-region');
    const $listNav = $('.header__dropdown-links').find('.list-nav');
    $addLinkButton.appendTo($listNav);
  }

  initInteractions () {
    $('.header').on('click', '.header__dropdown-toggle', () => {
      this.setHeaderDropdownOpen(!$('.header').hasClass('open'));
    });

    $('#bc-lp-video-player').on('click', '.video-player__curtain', () => {
      this.wasInteracted = true;
      $('.video-player__curtain').hide();
      this.playVideo();
    });
  }

  initVideoPlayer () {
    // there is no next video
    videoFinisher.onNextVideo(function () { });

    this.bcVideoPlayer = new BcVideoPlayer();

    this.bcVideoPlayer.addEventListener(BcVideoPlayer.EVENT_VIDEO_STARTED, () => {
      if (!this.wasInteracted) {
        this.bcVideoPlayer.pause();
      }
    });

    this.bcVideoPlayer.init();
  }

  playVideo () {
    this.bcVideoPlayer.play();
    $('#bc-lp-video-player').find('.video-player__curtain')
      .hide();
  }

  resizeVideoPlayer () {
    const $videoWrapper = $('.video-player');
    const width = $videoWrapper.parent().width();
    const height = width * 9 / 16;

    $videoWrapper.height(height);
    $videoWrapper.width('100%');
  }

  resizeHeader () {
    // if we don't have an FMA, then use the toggle button itself
    let $target = $('.fma-wrapper.wrapper-1');
    if ($target.length === 0 || $target.hasClass('empty')) {
      $target = $('.header__dropdown-toggle');
    }

    if ($target.length) {
      // dropdown position will be overridden with CSS !important on tablet / desktop
      const $headerDropdown = $('.header__dropdown');
      const headerDropdownTop = $target.position().top + $target.outerHeight();
      $headerDropdown.css('top', headerDropdownTop);
    }
  }

  resizeFooter () {
    const $target = $('.footer__left');
    if ($target.length === 0) {
      // custom footer content replaces this item
      return;
    }

    // this will be negated by CSS !important @ mobile
    const targetOffset = ($target.position().left + $target.width()) + 30;
    const $right = $('.footer__right');
    $right.width('calc(100% - ' + targetOffset + 'px)');
  }

  setHeaderDropdownOpen (isOpen) {
    const $header = $('.header');
    const $fa = $header.find('.header__dropdown-toggle .fa');

    if (isOpen) {
      $header.addClass('open');
      $fa.removeClass('fa-bars').addClass('fa-times');
    } else {
      $header.removeClass('open');
      $fa.addClass('fa-bars').removeClass('fa-times');
    }
  }
}

// IE FIX: we need to bind this handler immediately otherwise load event can be fired before the binding in IE 11
$(window).on('load', () => {
  $('.video-player__meta').show();
});

const bcLandingPage = new BcLandingPage();

$(document).ready(function () {
  bcLandingPage.init();
  const bgImage = $('.video-bg').css('background-image');

  if (bgImage !== 'none' && bgImage !== 'url("")') {
    $('.video-bg-gradient').addClass('video-bg-gradient--hidden');
  }
});

$(window).resize(function () {
  bcLandingPage.resizeVideoPlayer();
  bcLandingPage.resizeHeader();
  bcLandingPage.resizeFooter();
});
